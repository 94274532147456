import { default as _5_45month_45cart_45offerg42f9jLdTrMeta } from "/codebuild/output/src1697417430/src/zeronet-web/pages/5-month-cart-offer.vue?macro=true";
import { default as _6_45month_45half_45price86WeUC7sjAMeta } from "/codebuild/output/src1697417430/src/zeronet-web/pages/6-month-half-price.vue?macro=true";
import { default as aboutnVIu9JrnvoMeta } from "/codebuild/output/src1697417430/src/zeronet-web/pages/about.vue?macro=true";
import { default as billingrhSBSQjQU5Meta } from "/codebuild/output/src1697417430/src/zeronet-web/pages/account/billing.vue?macro=true";
import { default as indexRKymRdT41wMeta } from "/codebuild/output/src1697417430/src/zeronet-web/pages/account/index.vue?macro=true";
import { default as profilefijCzTzqoGMeta } from "/codebuild/output/src1697417430/src/zeronet-web/pages/account/profile.vue?macro=true";
import { default as accountbBR18TP4EjMeta } from "/codebuild/output/src1697417430/src/zeronet-web/pages/account.vue?macro=true";
import { default as authorizejfpEtOcdhhMeta } from "/codebuild/output/src1697417430/src/zeronet-web/pages/authorize.vue?macro=true";
import { default as bbc_45promo6Th0BspXoOMeta } from "/codebuild/output/src1697417430/src/zeronet-web/pages/bbc-promo.vue?macro=true";
import { default as chatx4JQanEdS0Meta } from "/codebuild/output/src1697417430/src/zeronet-web/pages/chat.vue?macro=true";
import { default as compass_45embedRyhlgydWgqMeta } from "/codebuild/output/src1697417430/src/zeronet-web/pages/compass-embed.vue?macro=true";
import { default as compassaAy1z32NHJMeta } from "/codebuild/output/src1697417430/src/zeronet-web/pages/compass.vue?macro=true";
import { default as contactMCRXsqXINRMeta } from "/codebuild/output/src1697417430/src/zeronet-web/pages/contact.vue?macro=true";
import { default as hyperfibre5X3WF8CYonMeta } from "/codebuild/output/src1697417430/src/zeronet-web/pages/hyperfibre.vue?macro=true";
import { default as indexDRHP9BDFOWMeta } from "/codebuild/output/src1697417430/src/zeronet-web/pages/index.vue?macro=true";
import { default as internet_45speed_45testyd1jfEfXMQMeta } from "/codebuild/output/src1697417430/src/zeronet-web/pages/internet-speed-test.vue?macro=true";
import { default as loginRdyc3hWo8CMeta } from "/codebuild/output/src1697417430/src/zeronet-web/pages/login.vue?macro=true";
import { default as logoutciP6EyYz5kMeta } from "/codebuild/output/src1697417430/src/zeronet-web/pages/logout.vue?macro=true";
import { default as our_45planskQy0HQirPgMeta } from "/codebuild/output/src1697417430/src/zeronet-web/pages/our-plans.vue?macro=true";
import { default as plansWHBCKFtAh9Meta } from "/codebuild/output/src1697417430/src/zeronet-web/pages/plans.vue?macro=true";
import { default as privacy1MTpbs1TapMeta } from "/codebuild/output/src1697417430/src/zeronet-web/pages/privacy.vue?macro=true";
import { default as promotionsgeGTG4dBBsMeta } from "/codebuild/output/src1697417430/src/zeronet-web/pages/promotions.vue?macro=true";
import { default as _1tJROU6WynnMeta } from "/codebuild/output/src1697417430/src/zeronet-web/pages/register/1.vue?macro=true";
import { default as _2tIq2m4xQVCMeta } from "/codebuild/output/src1697417430/src/zeronet-web/pages/register/2.vue?macro=true";
import { default as _3LCQNEnl1E0Meta } from "/codebuild/output/src1697417430/src/zeronet-web/pages/register/3.vue?macro=true";
import { default as _4DHpG9wJEdgMeta } from "/codebuild/output/src1697417430/src/zeronet-web/pages/register/4.vue?macro=true";
import { default as _5aOgxRAqmNKMeta } from "/codebuild/output/src1697417430/src/zeronet-web/pages/register/5.vue?macro=true";
import { default as _6QD3YG4kOYVMeta } from "/codebuild/output/src1697417430/src/zeronet-web/pages/register/6.vue?macro=true";
import { default as indexm6VhVy6OUQMeta } from "/codebuild/output/src1697417430/src/zeronet-web/pages/register/index.vue?macro=true";
import { default as successQYjkWWsdIIMeta } from "/codebuild/output/src1697417430/src/zeronet-web/pages/register/success.vue?macro=true";
import { default as registerbjifvQUETvMeta } from "/codebuild/output/src1697417430/src/zeronet-web/pages/register.vue?macro=true";
import { default as simply_45broadbandKytQ1bdepjMeta } from "/codebuild/output/src1697417430/src/zeronet-web/pages/simply-broadband.vue?macro=true";
import { default as studentcard_45exclusive_45offerQia2929GuUMeta } from "/codebuild/output/src1697417430/src/zeronet-web/pages/studentcard-exclusive-offer.vue?macro=true";
import { default as sustainability2BAW38E2Y8Meta } from "/codebuild/output/src1697417430/src/zeronet-web/pages/sustainability.vue?macro=true";
import { default as termsulvAQYpFrEMeta } from "/codebuild/output/src1697417430/src/zeronet-web/pages/terms.vue?macro=true";
export default [
  {
    name: _5_45month_45cart_45offerg42f9jLdTrMeta?.name ?? "5-month-cart-offer",
    path: _5_45month_45cart_45offerg42f9jLdTrMeta?.path ?? "/5-month-cart-offer",
    meta: _5_45month_45cart_45offerg42f9jLdTrMeta || {},
    alias: _5_45month_45cart_45offerg42f9jLdTrMeta?.alias || [],
    redirect: _5_45month_45cart_45offerg42f9jLdTrMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1697417430/src/zeronet-web/pages/5-month-cart-offer.vue").then(m => m.default || m)
  },
  {
    name: _6_45month_45half_45price86WeUC7sjAMeta?.name ?? "6-month-half-price",
    path: _6_45month_45half_45price86WeUC7sjAMeta?.path ?? "/6-month-half-price",
    meta: _6_45month_45half_45price86WeUC7sjAMeta || {},
    alias: _6_45month_45half_45price86WeUC7sjAMeta?.alias || [],
    redirect: _6_45month_45half_45price86WeUC7sjAMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1697417430/src/zeronet-web/pages/6-month-half-price.vue").then(m => m.default || m)
  },
  {
    name: aboutnVIu9JrnvoMeta?.name ?? "about",
    path: aboutnVIu9JrnvoMeta?.path ?? "/about",
    meta: aboutnVIu9JrnvoMeta || {},
    alias: aboutnVIu9JrnvoMeta?.alias || [],
    redirect: aboutnVIu9JrnvoMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1697417430/src/zeronet-web/pages/about.vue").then(m => m.default || m)
  },
  {
    path: accountbBR18TP4EjMeta?.path ?? "/account",
    children: [
  {
    name: billingrhSBSQjQU5Meta?.name ?? "account-billing",
    path: billingrhSBSQjQU5Meta?.path ?? "billing",
    meta: billingrhSBSQjQU5Meta || {},
    alias: billingrhSBSQjQU5Meta?.alias || [],
    redirect: billingrhSBSQjQU5Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src1697417430/src/zeronet-web/pages/account/billing.vue").then(m => m.default || m)
  },
  {
    name: indexRKymRdT41wMeta?.name ?? "account",
    path: indexRKymRdT41wMeta?.path ?? "",
    meta: indexRKymRdT41wMeta || {},
    alias: indexRKymRdT41wMeta?.alias || [],
    redirect: indexRKymRdT41wMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1697417430/src/zeronet-web/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: profilefijCzTzqoGMeta?.name ?? "account-profile",
    path: profilefijCzTzqoGMeta?.path ?? "profile",
    meta: profilefijCzTzqoGMeta || {},
    alias: profilefijCzTzqoGMeta?.alias || [],
    redirect: profilefijCzTzqoGMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1697417430/src/zeronet-web/pages/account/profile.vue").then(m => m.default || m)
  }
],
    name: accountbBR18TP4EjMeta?.name ?? undefined,
    meta: accountbBR18TP4EjMeta || {},
    alias: accountbBR18TP4EjMeta?.alias || [],
    redirect: accountbBR18TP4EjMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1697417430/src/zeronet-web/pages/account.vue").then(m => m.default || m)
  },
  {
    name: authorizejfpEtOcdhhMeta?.name ?? "authorize",
    path: authorizejfpEtOcdhhMeta?.path ?? "/authorize",
    meta: authorizejfpEtOcdhhMeta || {},
    alias: authorizejfpEtOcdhhMeta?.alias || [],
    redirect: authorizejfpEtOcdhhMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1697417430/src/zeronet-web/pages/authorize.vue").then(m => m.default || m)
  },
  {
    name: bbc_45promo6Th0BspXoOMeta?.name ?? "bbc-promo",
    path: bbc_45promo6Th0BspXoOMeta?.path ?? "/bbc-promo",
    meta: bbc_45promo6Th0BspXoOMeta || {},
    alias: bbc_45promo6Th0BspXoOMeta?.alias || [],
    redirect: bbc_45promo6Th0BspXoOMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1697417430/src/zeronet-web/pages/bbc-promo.vue").then(m => m.default || m)
  },
  {
    name: chatx4JQanEdS0Meta?.name ?? "chat",
    path: chatx4JQanEdS0Meta?.path ?? "/chat",
    meta: chatx4JQanEdS0Meta || {},
    alias: chatx4JQanEdS0Meta?.alias || [],
    redirect: chatx4JQanEdS0Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src1697417430/src/zeronet-web/pages/chat.vue").then(m => m.default || m)
  },
  {
    name: compass_45embedRyhlgydWgqMeta?.name ?? "compass-embed",
    path: compass_45embedRyhlgydWgqMeta?.path ?? "/compass-embed",
    meta: compass_45embedRyhlgydWgqMeta || {},
    alias: compass_45embedRyhlgydWgqMeta?.alias || [],
    redirect: compass_45embedRyhlgydWgqMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1697417430/src/zeronet-web/pages/compass-embed.vue").then(m => m.default || m)
  },
  {
    name: compassaAy1z32NHJMeta?.name ?? "compass",
    path: compassaAy1z32NHJMeta?.path ?? "/compass",
    meta: compassaAy1z32NHJMeta || {},
    alias: compassaAy1z32NHJMeta?.alias || [],
    redirect: compassaAy1z32NHJMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1697417430/src/zeronet-web/pages/compass.vue").then(m => m.default || m)
  },
  {
    name: contactMCRXsqXINRMeta?.name ?? "contact",
    path: contactMCRXsqXINRMeta?.path ?? "/contact",
    meta: contactMCRXsqXINRMeta || {},
    alias: contactMCRXsqXINRMeta?.alias || [],
    redirect: contactMCRXsqXINRMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1697417430/src/zeronet-web/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: hyperfibre5X3WF8CYonMeta?.name ?? "hyperfibre",
    path: hyperfibre5X3WF8CYonMeta?.path ?? "/hyperfibre",
    meta: hyperfibre5X3WF8CYonMeta || {},
    alias: hyperfibre5X3WF8CYonMeta?.alias || [],
    redirect: hyperfibre5X3WF8CYonMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1697417430/src/zeronet-web/pages/hyperfibre.vue").then(m => m.default || m)
  },
  {
    name: indexDRHP9BDFOWMeta?.name ?? "index",
    path: indexDRHP9BDFOWMeta?.path ?? "/",
    meta: indexDRHP9BDFOWMeta || {},
    alias: indexDRHP9BDFOWMeta?.alias || [],
    redirect: indexDRHP9BDFOWMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1697417430/src/zeronet-web/pages/index.vue").then(m => m.default || m)
  },
  {
    name: internet_45speed_45testyd1jfEfXMQMeta?.name ?? "internet-speed-test",
    path: internet_45speed_45testyd1jfEfXMQMeta?.path ?? "/internet-speed-test",
    meta: internet_45speed_45testyd1jfEfXMQMeta || {},
    alias: internet_45speed_45testyd1jfEfXMQMeta?.alias || [],
    redirect: internet_45speed_45testyd1jfEfXMQMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1697417430/src/zeronet-web/pages/internet-speed-test.vue").then(m => m.default || m)
  },
  {
    name: loginRdyc3hWo8CMeta?.name ?? "login",
    path: loginRdyc3hWo8CMeta?.path ?? "/login",
    meta: loginRdyc3hWo8CMeta || {},
    alias: loginRdyc3hWo8CMeta?.alias || [],
    redirect: loginRdyc3hWo8CMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1697417430/src/zeronet-web/pages/login.vue").then(m => m.default || m)
  },
  {
    name: logoutciP6EyYz5kMeta?.name ?? "logout",
    path: logoutciP6EyYz5kMeta?.path ?? "/logout",
    meta: logoutciP6EyYz5kMeta || {},
    alias: logoutciP6EyYz5kMeta?.alias || [],
    redirect: logoutciP6EyYz5kMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1697417430/src/zeronet-web/pages/logout.vue").then(m => m.default || m)
  },
  {
    name: our_45planskQy0HQirPgMeta?.name ?? "our-plans",
    path: our_45planskQy0HQirPgMeta?.path ?? "/our-plans",
    meta: our_45planskQy0HQirPgMeta || {},
    alias: our_45planskQy0HQirPgMeta?.alias || [],
    redirect: our_45planskQy0HQirPgMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1697417430/src/zeronet-web/pages/our-plans.vue").then(m => m.default || m)
  },
  {
    name: plansWHBCKFtAh9Meta?.name ?? "plans",
    path: plansWHBCKFtAh9Meta?.path ?? "/plans",
    meta: plansWHBCKFtAh9Meta || {},
    alias: plansWHBCKFtAh9Meta?.alias || [],
    redirect: plansWHBCKFtAh9Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src1697417430/src/zeronet-web/pages/plans.vue").then(m => m.default || m)
  },
  {
    name: privacy1MTpbs1TapMeta?.name ?? "privacy",
    path: privacy1MTpbs1TapMeta?.path ?? "/privacy",
    meta: privacy1MTpbs1TapMeta || {},
    alias: privacy1MTpbs1TapMeta?.alias || [],
    redirect: privacy1MTpbs1TapMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1697417430/src/zeronet-web/pages/privacy.vue").then(m => m.default || m)
  },
  {
    name: promotionsgeGTG4dBBsMeta?.name ?? "promotions",
    path: promotionsgeGTG4dBBsMeta?.path ?? "/promotions",
    meta: promotionsgeGTG4dBBsMeta || {},
    alias: promotionsgeGTG4dBBsMeta?.alias || [],
    redirect: promotionsgeGTG4dBBsMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1697417430/src/zeronet-web/pages/promotions.vue").then(m => m.default || m)
  },
  {
    path: registerbjifvQUETvMeta?.path ?? "/register",
    children: [
  {
    name: _1tJROU6WynnMeta?.name ?? "register-1",
    path: _1tJROU6WynnMeta?.path ?? "1",
    meta: _1tJROU6WynnMeta || {},
    alias: _1tJROU6WynnMeta?.alias || [],
    redirect: _1tJROU6WynnMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1697417430/src/zeronet-web/pages/register/1.vue").then(m => m.default || m)
  },
  {
    name: _2tIq2m4xQVCMeta?.name ?? "register-2",
    path: _2tIq2m4xQVCMeta?.path ?? "2",
    meta: _2tIq2m4xQVCMeta || {},
    alias: _2tIq2m4xQVCMeta?.alias || [],
    redirect: _2tIq2m4xQVCMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1697417430/src/zeronet-web/pages/register/2.vue").then(m => m.default || m)
  },
  {
    name: _3LCQNEnl1E0Meta?.name ?? "register-3",
    path: _3LCQNEnl1E0Meta?.path ?? "3",
    meta: _3LCQNEnl1E0Meta || {},
    alias: _3LCQNEnl1E0Meta?.alias || [],
    redirect: _3LCQNEnl1E0Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src1697417430/src/zeronet-web/pages/register/3.vue").then(m => m.default || m)
  },
  {
    name: _4DHpG9wJEdgMeta?.name ?? "register-4",
    path: _4DHpG9wJEdgMeta?.path ?? "4",
    meta: _4DHpG9wJEdgMeta || {},
    alias: _4DHpG9wJEdgMeta?.alias || [],
    redirect: _4DHpG9wJEdgMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1697417430/src/zeronet-web/pages/register/4.vue").then(m => m.default || m)
  },
  {
    name: _5aOgxRAqmNKMeta?.name ?? "register-5",
    path: _5aOgxRAqmNKMeta?.path ?? "5",
    meta: _5aOgxRAqmNKMeta || {},
    alias: _5aOgxRAqmNKMeta?.alias || [],
    redirect: _5aOgxRAqmNKMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1697417430/src/zeronet-web/pages/register/5.vue").then(m => m.default || m)
  },
  {
    name: _6QD3YG4kOYVMeta?.name ?? "register-6",
    path: _6QD3YG4kOYVMeta?.path ?? "6",
    meta: _6QD3YG4kOYVMeta || {},
    alias: _6QD3YG4kOYVMeta?.alias || [],
    redirect: _6QD3YG4kOYVMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1697417430/src/zeronet-web/pages/register/6.vue").then(m => m.default || m)
  },
  {
    name: indexm6VhVy6OUQMeta?.name ?? "register",
    path: indexm6VhVy6OUQMeta?.path ?? "",
    meta: indexm6VhVy6OUQMeta || {},
    alias: indexm6VhVy6OUQMeta?.alias || [],
    redirect: indexm6VhVy6OUQMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1697417430/src/zeronet-web/pages/register/index.vue").then(m => m.default || m)
  },
  {
    name: successQYjkWWsdIIMeta?.name ?? "register-success",
    path: successQYjkWWsdIIMeta?.path ?? "success",
    meta: successQYjkWWsdIIMeta || {},
    alias: successQYjkWWsdIIMeta?.alias || [],
    redirect: successQYjkWWsdIIMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1697417430/src/zeronet-web/pages/register/success.vue").then(m => m.default || m)
  }
],
    name: registerbjifvQUETvMeta?.name ?? undefined,
    meta: registerbjifvQUETvMeta || {},
    alias: registerbjifvQUETvMeta?.alias || [],
    redirect: registerbjifvQUETvMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1697417430/src/zeronet-web/pages/register.vue").then(m => m.default || m)
  },
  {
    name: simply_45broadbandKytQ1bdepjMeta?.name ?? "simply-broadband",
    path: simply_45broadbandKytQ1bdepjMeta?.path ?? "/simply-broadband",
    meta: simply_45broadbandKytQ1bdepjMeta || {},
    alias: simply_45broadbandKytQ1bdepjMeta?.alias || [],
    redirect: simply_45broadbandKytQ1bdepjMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1697417430/src/zeronet-web/pages/simply-broadband.vue").then(m => m.default || m)
  },
  {
    name: studentcard_45exclusive_45offerQia2929GuUMeta?.name ?? "studentcard-exclusive-offer",
    path: studentcard_45exclusive_45offerQia2929GuUMeta?.path ?? "/studentcard-exclusive-offer",
    meta: studentcard_45exclusive_45offerQia2929GuUMeta || {},
    alias: studentcard_45exclusive_45offerQia2929GuUMeta?.alias || [],
    redirect: studentcard_45exclusive_45offerQia2929GuUMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1697417430/src/zeronet-web/pages/studentcard-exclusive-offer.vue").then(m => m.default || m)
  },
  {
    name: sustainability2BAW38E2Y8Meta?.name ?? "sustainability",
    path: sustainability2BAW38E2Y8Meta?.path ?? "/sustainability",
    meta: sustainability2BAW38E2Y8Meta || {},
    alias: sustainability2BAW38E2Y8Meta?.alias || [],
    redirect: sustainability2BAW38E2Y8Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src1697417430/src/zeronet-web/pages/sustainability.vue").then(m => m.default || m)
  },
  {
    name: termsulvAQYpFrEMeta?.name ?? "terms",
    path: termsulvAQYpFrEMeta?.path ?? "/terms",
    meta: termsulvAQYpFrEMeta || {},
    alias: termsulvAQYpFrEMeta?.alias || [],
    redirect: termsulvAQYpFrEMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1697417430/src/zeronet-web/pages/terms.vue").then(m => m.default || m)
  }
]